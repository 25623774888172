<template>
  <div>
    <div class="custom-header">
      <base-button
        class="elite-button add"
        icon
        size="sm"
        @click="openAddFileModal"
        v-if="$currentUserCan($permissions.PERM_CREATE_FILES)"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("FILES.ADD_FILE") }}| {{object.type}}
        </span>
      </base-button>
      <base-button
        class="elite-button add"
        icon
        size="sm"
        @click="extractFiles"
        v-if="object.type == 'properties'"
      >
        <span class="btn-inner--icon">
          <i class="fa fa-download" aria-hidden="true"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("FILES.DOWNLOAD_ALL_FILES") }}
        </span>
      </base-button>
    </div>

    <file-list-table
      :key="renderKey"
      v-if="object.id"
      :filterObjectId="object.id"
      :filterObjectType="object.type"
      @onEditFile="openEditFileModal"
      @onDeleteFile="deleteFile"
      @onViewFile="openViewFileModal"
    />

    <file-add-form-modal
      v-if="object.id"
      :showModal="showAddFormModal"
      :object="object"
      @onCloseModal="closeModal"
    />

    <file-edit-form-modal
      v-if="object.id"
      :showModal="showEditFormModal"
      :fileData="file"
      @onCloseModal="closeModal"
    />

    <file-view-modal
      v-if="object.id"
      :showModal="showViewModal"
      :fileData="file"
      @onCloseModal="closeModal"
      @fileUpdated="refreshFile"
      @editFile="
        (id) => {
          closeModal(false);
          openEditFileModal(id);
        }
      "
      @deleteFile="
        (id) => {
          closeModal(false);
          deleteFile(id);
        }
      "
    />
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import swal from "sweetalert2";
import FileListTable from "./partials/FileListTable.vue";
import defaultFile from "./defaultFile";
import FileAddFormModal from "./partials/FileAddFormModal.vue";
import FileEditFormModal from "./partials/FileEditFormModal.vue";
import FileViewModal from "./partials/FileViewModal.vue";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';


export default {
  components: {
    FileListTable,
    FileAddFormModal,
    FileEditFormModal,
    FileViewModal,
    JSZip,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    object: {
      type: Object,
      default: null,
      description: "Files linked object",
    },
  },

  computed: {},

  watch: {},

  data() {
    return {
      renderKey: 0,
      file: cloneDeep(defaultFile),
      showAddFormModal: false,
      showEditFormModal: false,
      showViewModal: false,
    };
  },

  methods: {
    openAddFileModal() {
      this.showAddFormModal = true;
    },
    async extractFiles() {
      let propertyNumber = this.object.property_number;
      try {
        let params = {
          include: "organization,tags,allowedLocations,object",
        };
        if (this.object) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectType: this.object.type,
            },
          };
        }
        if (this.object) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectId: this.object.id,
            },
          };
        }
        await this.$store.dispatch("files/list", params);
        let files = this.$store.getters["files/list"];
        var zip = new JSZip();
        for (let i = 0; i < files.length; i++) {
          const fileContent = await this.$store.dispatch("files/getFileContent", files[i].url);
          zip.file(this.replaceAccents( files[i].name ), fileContent, {binary:true})
        }
        zip.generateAsync({type: "blob"}).then(function(content) {
          saveAs(content, propertyNumber+".zip")
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async openEditFileModal(id) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", id);
      this.file = this.$store.getters["files/file"];
      this.showEditFormModal = true;
      swal.close();
    },

    async openViewFileModal(id) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", id);
      this.file = this.$store.getters["files/file"];
      this.showViewModal = true;
      swal.close();
    },
    replaceAccents(r){
      r=r.toLowerCase();
      r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
      r = r.replace(new RegExp("æ", 'g'),"ae");
      r = r.replace(new RegExp("ç", 'g'),"c");
      r = r.replace(new RegExp("[èéêë]", 'g'),"e");
      r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
      r = r.replace(new RegExp("ñ", 'g'),"n");                            
      r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
      r = r.replace(new RegExp("œ", 'g'),"oe");
      r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
      r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
      return r;
    },

    closeModal(refresh = false) {
      this.showAddFormModal = false;
      this.showEditFormModal = false;
      this.showViewModal = false;
      this.file = cloneDeep(defaultFile);
      if (refresh) {
        this.renderKey = this.renderKey + 1;
      }
    },

    async refreshFile() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", this.file.id);
      this.file = this.$store.getters["files/file"];
      swal.close();
    },

    async deleteFile(id) {
      const confirmation = await swal.fire({
        title: this.$t("FILES.DELETE_THIS_FILE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          swal.fire({
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              swal.showLoading();
            },
          });
          await this.$store.dispatch("files/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("FILES.FILE_DELETED"),
          });
          this.closeModal(true);
          swal.close();
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
